import React from "react";
import styled from "@emotion/styled";
import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";
import { BlogWrapper } from "../../components/BlogLayout";

const SubtleHeading = styled.h1`
  font-family: Rubik, sans-serif;
  color: #999999;
  font-size: 2em;
  padding-bottom: 1rem;
  padding-top: 1rem;
`;

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <BlogWrapper>
          <SubtleHeading>Recent articles</SubtleHeading>
          <section className="section">
            <div className="container">
              <div className="content">
                <BlogRoll />
              </div>
            </div>
          </section>
        </BlogWrapper>
      </Layout>
    );
  }
}
